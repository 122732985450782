/* Sign in  */
*{
    font-family: Montserrat;
}
.layout-signin{
    width: 100%;
    height: auto;
    padding: 2% 0;
    background: var(--background-white) ;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.layout-signin .logo{
    position: relative;
    right: 25%;

}

.layout-signin .form-signin{
    width: 60%;
    display: inline-flex;
    margin: 3% ;
    padding: 2%;
    align-items: flex-start;
    gap: 68px;

    border-radius: 4px;
    background: var(--blanc);
    box-shadow: 0px 10px 30px 0px rgba(62, 19, 77, 0.25);
}

.form-signin form {
    width: 100%;
}

.parent-form-input{
    margin: 3% 0;
}


.form-signin form div input {
    width: 100%;
    height: 54px;
    flex-shrink: 0;
    margin: 4px 0;

    border-radius: 4px;
    border: 1px solid var(--bleu-ciel);
    background: var(--blanc);
}

.container-right-signin{
    width: 100%;

}

.card-title-signin {

    width: 100%;
    height: 54px;
    flex-shrink: 0;
    text-align: center;
    

    border-radius: 4px;
    background: var(--blanc);
    
    font-family: Montserrat;
    /* font-size: 14px; */
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    /* Large Shadow (for pop-up windows) */
    box-shadow: 0px 10px 30px 0px rgba(62, 19, 77, 0.47);
}

.title-google{
    display: flex;
    justify-content: space-around;
}
.card-title-signin img{
    width: 50px;
}


.card-title-signin h3 {
    padding: 4%;
    font-size: 15px;

}

.btn-form-connection{
    width: 100%;
    height: 54px;
    flex-shrink: 0;
    margin: 4px 0;

    border-radius: 4px;
    font-size: 14px;
    font-family: Montserrat;
    border: none;
    color: var(--blanc);
    background: var(--orange-brule);
}

.container-right-signin img{
    width: 70%;
    margin: 3% 8%;
}

/* End sign in */





/* Sign Out  */
.btn-sign-out{
    width: 120px;
    height: 34px;
    padding: 5px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;

    border-radius: 4px;
    border: none;
    color: var(--blanc);
    background: var(--orange-brule);

    color: var(--blanc);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#btn-signin{
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
}