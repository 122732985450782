@import url('./Variables.css');

/* New product */
/* .a-new-product{
    display: flex;
} */



.parent-new-product {
    width: 80%;
    height: auto;
    margin-left: 20%;
    padding: 2% 0;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
.new-category{
    padding-bottom: 4% ;
    margin-left: 8%;
}
/* .custom-layout-product {
    height: 110%;
    margin: 5% 0;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    background: var(--blanc);
    box-shadow: 0px 10px 30px 0px var(--box-shadow-color);
} */

.new-product {
    width: 80%;
    height: auto;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

}

.content-right-new-product{
    background-color: #4b4b4b;

}


.new-product .form-display {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.new-product .form-display div {
    width: 40%;
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.new-product .form-display select{
    width: 100%;
}
.new-product  div input {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    margin: 4px 0;

    border-radius: 4px;
    border: 1px solid var(--bleu-ciel);
    background: var(--blanc);
}
.form-display #description{
    width: 80%;
}
.new-product #description textarea {
    width: 100%;
    height: 100px;
    flex-shrink: 0;
    margin: 4px 0;

    border-radius: 4px;
    border: 1px solid var(--bleu-ciel);
    background: var(--blanc);
}

.custom-layout-product .validate{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.validate .add-new-element {
    width: 350px;
    height: 54px;
    padding: 15px 60px;
    margin: 10px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    text-decoration: none;
    position: relative;
    top: 40px;
    border-radius: 4px;
    border: none;
    color: var(--blanc);
    background: var(--orange-brule);

    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

/* End styles of new product */

/* All products Styles */

/* end */

/* Show ¨Product */
.img-product{
    width: 30%;
}

.faq-item {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    padding: 10px 20px;
}
.faq-item p{
    width: 80%;
}

.faq-content hr {
    width: 80%;
    margin: 0 10%;
    height: 0.5px;
    background-color: var(--orange-brule);
    border: none;
}

.faq-description {
    color: #4b4b4b;
    padding: 10px 20px;
    width: 80%;
    line-height: 30px;

}

.arrow-faq {
    font-size: 20px;
    font-weight: 900;    
    cursor: pointer;
}

.chevron {
    color: var(--orange-brule);
    transform: translate(-50%, -50%) rotate(180deg);
    /* Pivoter de 90 degrés dans le sens antihoraire */

}

p{
    line-height: 30px;
    white-space: pre-line ;
}

.faq-new-product{
    width: 80%;
    margin: 2% 10%;

}

.more-faq{
    width: 30%;
    padding: 2%;
    background: #FFEFDD;
    border-radius: 4px;
    border: 1px solid var(--bleu-ciel);

}

/* Add New Category or new name product */
.content-manager-add-catProd{
    align-items: flex-start;
}
.manager-add-catProd{
    display: flex;
    align-items: center;
}

.manager-add-catProd input{

}

.btn-add-catProd button{
    width: 30% !important;
    padding: 0 !important;
    display: flex;
    justify-content: center;
}
.manager-add-catProd select{
    margin: 0 10px;
    height: 3.1rem;
    width: 18.5rem;

}