/* .details-subscription .new-product{
    background-color: aquamarine;
    width: 50%;
} */
.show-subscription {
    padding: 1%;
    
}
.show-subscription .layout-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
}
.show-subscription .layout-right img{
    margin: -40px 0 0 0;
}
.show-subscription .layout-right h1{
    line-height: 40px;

}

.show-subscription .layout-left {
    display: flex;
    flex-direction: column;
    }

.show-subscription .layout-left form{
    width: 100%;
    display: flex;
    flex-direction: row;
    }
.show-subscription .layout-left form input{
    width: 100%;
    white-space: pre-line;
}

#edit-infos-sub{
    text-decoration: none;
    position: relative;
    top: 30px;
}
.manual-validate{
    border: none;
    background-color: var(--orange-brule);
    width: 50%;
    height: 54px;
    color: white;
    font-size: 19px;
    font-weight: 600;
    border-radius: 4px;

}

#see-more-subs{
    margin-left: 83%;
    margin-top: -18%;
    padding: 2%;
    width: 20%;
    cursor: pointer;
    color: var(--orange-brule);
    text-decoration: underline;
}