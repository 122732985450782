#share-not-validate{
    width: 10rem;
    height: 4rem;
    background-color: red;
    color: var(--blanc);
    padding: 5px;
}
#share-validate{
    width: 10rem;
    height: 4rem;
    background-color: rgb(0, 255, 26);
    color: var(--blanc);
    padding: 5px;

}

.btn-status-share{
    width: 300px;
    height: 54px;
    padding: 15px 60px;
    margin: 10px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    font-size: large;
    font-weight: bold                                                                                                                                                ;
    border-radius: 4px;
    border: none;
    color: var(--blanc);
}
.validate-share{
    background-color: rgb(0, 255, 26);
}
.decline-share{
    background-color: red;

}