.new-post{
    width: 50%;
    flex-direction: column;
}

.new-post .form-display div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
}

.new-post .form-display div input {
    width: 240px;
    height: 44px;
    flex-shrink: 0;
    margin: 4px 0;

    border-radius: 4px;
    border: 1px solid var(--bleu-ciel);
    background: var(--blanc);
}

.subsection{
    padding: 2%  ;
}