@import url('./Variables.css');

*{
    margin: 0;
    padding: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
}

/* .dashboard{
    background-color: var(--background-white);
} */

/* Side Bar */

.side-bar{
    width: 20%;
    height: 110vh;
    position: fixed;
    top : -10px ;
    flex-shrink: 0;
    background-color: var(--bleu-sarcelle);

}

.side-bar .logo-side-bar{
    padding: 25px 40px;
}

.side-bar .navigation{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: auto;
    padding: 10px 40px;
}
.side-bar .navigation .navigation-link{

    color: var(--blanc);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    padding: 10px 0;
}

/* .side-bar .navigation .navigation-link:hover{

    background: var(--gris-clair);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--noir);
    width: 150px;
    
} */

.side-bar .navigation .navigation-link.active {
    background: var(--orange-brule);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* color: var(--noir); */
    width: 130%;
    padding-left: 15% ;
    margin-left: -30%;
    margin-bottom: 4%;
    border-radius: 0 40px 40px 0 ;
}



/* Main Layout Style */

.main-layout-dashboard{
    width: 80%;
    height: 250px;
    margin: -1% 20%;
    position: fixed;
    top : 10px ;
    background: var(--background-white);
    z-index: 100;

}

.card-main-layout-dashboard{
    display: flex;
    width: 17%;
    margin: 10px ;
    padding: 32px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    
    border-radius: 4px;
    background: var(--bleu-sarcelle);
}

.main-layout-dashboard .title{
    padding: 1%;
}
.card-dashboard {
    width: 98%;
    margin: 0 1%;
    display: flex;
    /* justify-content: space-around; */
}

/* Custom List */

.custom-list{
    width: 80%;
    /* height: 100px; */
    margin:  20%;

    color: var(--noir);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 2px; */
}

.custom-list .list-elements{
    width: 96%;
    height: 50px;
    margin: 10px 2%;
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 10px 30px 0px rgba(62, 19, 77, 0.25)

}
.custom-list .custom-list-details{
    position: relative;
    top: 30px;
}


.custom-list .nav-bar-list{
    width: 77%;
    position: fixed;
    /* top: 10px; */
    margin: -2% 0 0 1.5% ;
    background: var(--bleu-sarcelle);
    color: #FFF;
    font-weight: 600;
    z-index: 100;
    
}
.filter-search{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 18%;
    height: 100vh;
    position: fixed !important;
    z-index: 200;
    left: 0%;
    top: 0%;
    background-color: var(--bleu-sarcelle);
    padding: 10px;

}
.filter-search input {
    border: none;
    border-radius: 4px;
    font-size: 18px;
    padding: 0.7rem;
    border: solid 1px var(--bleu-sarcelle);
}
.filter-search input:focus{
    outline: none; /* Supprime la bordure par défaut du navigateur */
    color: var(--bleu-sarcelle);
    background-color: #FFF;

}
.search{
    position: fixed !important;
    z-index: 100;
    right: 1.5% ;
    top: 11rem;
}
.filter-items{
    display: none;
}
#classSeeMoreActive{
    top: 9rem;
    display: block;
}

.export-btn{
    display: none;
    
}

#btn-export-data{
    display: block;
    position: relative;
    top: -6rem;
    border-radius: 4px;
    font-size: 18px;
    padding: 0.7rem;
    border: solid 1px var(--orange-brule);
    background-color: var(--orange-brule);
    color: #FFF;
    cursor: pointer;
}

.search input{
    border: none;
    border-radius: 4px;
    font-size: 18px;
    padding: 0.7rem;
    border: solid 1px var(--bleu-sarcelle);
    
}
.search input:focus{
    outline: none; /* Supprime la bordure par défaut du navigateur */
    background: var(--bleu-sarcelle);
    color: #FFF;

}
@media screen and (min-width : 1290px) {
    .custom-list .nav-bar-list{
        width: 77%;
        position: fixed;
        /* top: 10px; */
        margin: -6% 0% 0 1.5% ;
        background: var(--bleu-sarcelle);
        color: #FFF;
        font-weight: 600;
        z-index: 100;
        
    }
    
}
.custom-list .nav-bar-list ul{
    z-index: 100;
    
}

.custom-list .list-elements ul{
    width: 69%;
    display: flex;
    justify-content: space-around;

    display: flex;
    align-items: center;
    list-style-type: none;
}
.custom-list .list-elements ul li{
    width: 20%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
} 

.custom-list .list-elements .actions{
    width: 20%;
    display: flex;
    justify-content: space-around;
    display: flex;
    align-items: center;
} 

.custom-list .list-elements .actions img{
    width: 29px;

} 


/* Histogram */

.graphs{
    width: 80%;
    height: 22rem;
    margin: 0 21%  ;
    position: fixed;
    top: 17rem;
    padding: 5px;
}

.graphs-details {
    display: flex;


}

.graphs-details  div{
    width: 90%;


}

.graphs span{
    color: var(--bleu-sarcelle);
    
}