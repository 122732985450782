/* New License */
select{
    width: 240px;
    height: 44px;
    flex-shrink: 0;
    margin: 4px 0;

    border-radius: 4px;
    border: 1px solid var(--bleu-ciel);
    background: var(--blanc);
}

.textarea{
    width: 240px;
    height: 44px;
    flex-shrink: 0;
    margin: 4px 0;

    border-radius: 4px;
    border: 1px solid var(--bleu-ciel);
    background: var(--blanc);
}
/* End New License */

/* SHow License */

.show-license{
    width: 100%;
    display: flex;
}

.show-elements-details{
    min-height: 80vh;
    width: 70%;
    text-align: justify;
    margin: 4% 24%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.show-elements-details span{
    white-space: pre-line

}


span{
    font-weight: 600;
    font-size: 18px;
}


/* Ens Show License */